import { useMemo } from 'react'
import {
    HttpLink,
    ApolloLink,
    ApolloClient,
    InMemoryCache
} from '@apollo/client'
import merge from 'deepmerge'
import { STORYBLOK_GQL_URL } from '../utils/constants'

export const SOURCE_CMS = 'cms'
export const SOURCE_PUBLIC = 'public'

let apolloClient

function createApolloClient(initialApolloState = {}, { isPreview }) {
    const publicLink = new HttpLink({
        uri: '/api/graphql',
        credentials: 'same-origin',
    })

    let token = process.env.NEXT_PUBLIC_SB_PUBLIC
    if (isPreview) {
        token = process.env.NEXT_PUBLIC_SB_PREVIEW
    }
    const cmsLink = new HttpLink({
        uri: STORYBLOK_GQL_URL,
        headers: {
            token,
            version: isPreview ? 'draft' : 'published'
        }
    })

    return new ApolloClient({
        ssrMode: !process.browser,
        cache: new InMemoryCache().restore(initialApolloState),
        link: ApolloLink.split(
            (operation) => operation.getContext().source === SOURCE_CMS,
            cmsLink,
            publicLink
        ),
    })
}

export function getApollo(initialState, options = {}) {
    const _apolloClient = apolloClient || createApolloClient(initialState, options)

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // get hydrated here
    if (initialState) {
    // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract()

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache)

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data)
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') {
        return _apolloClient
    }
    // Create the Apollo Client once in the client
    if (!apolloClient) {
        apolloClient = _apolloClient
    }

    return _apolloClient
}

export function useApollo(initialState) {
    const store = useMemo(() => getApollo(initialState), [initialState])
    return store
}
