/* eslint-disable import/extensions */
import { i18n } from '@lingui/core'
import enMessages from './en/messages.js'
import nlMessages from './nl/messages.js'
import frMessages from './fr/messages.js'
import trMessages from './tr/messages.js'
import * as constants from '../utils/constants'

const {
    LANGUAGE_EN: EN,
    LANGUAGE_NL: NL,
    LANGUAGE_FR: FR,
    LANGUAGE_TR: TR,
    DEFAULT_LANGUAGE,
} = constants

const catalog = [{
    code: EN,
    messages: enMessages
}, {
    code: NL,
    messages: nlMessages
}, {
    code: FR,
    messages: frMessages
}, {
    code: TR,
    messages: trMessages
}]

export default function createLocales(
    locale = DEFAULT_LANGUAGE,
) {
    catalog.forEach(({ code, plurals, messages: { messages } }) => {
        i18n.loadLocaleData(code, { plurals })
        i18n.load(code, messages)
    })
    i18n.activate(locale)
    return i18n
}
