import React from 'react'
import '../styles/globals.css'
import '../../public/fonts.css'
import { useRouter } from 'next/router'
import AppWithState from '../components/AppWithState'
import usePageLoader from '../components/hooks/usePageLoader'
import { useApollo } from '../apollo/client'

export default function App({
    Component,
    pageProps,
    err,
}) {
    usePageLoader()
    const router = useRouter()
    const apolloClient = useApollo(pageProps.initialApolloState)

    return (
        <AppWithState
            pageProps={pageProps}
            router={router}
            apolloClient={apolloClient}
        >
            <Component {...pageProps} err={err} />
        </AppWithState>
    )
}
