import React from 'react'
import { I18nProvider } from '@lingui/react'
import { ApolloProvider } from '@apollo/client'
import PagePropsProvider from './providers/PagePropsProvider'

const AppProviders = ({
    i18n,
    pageProps,
    apolloClient,
    children,
}) => (
    <PagePropsProvider value={pageProps}>
        <ApolloProvider client={apolloClient}>
            <I18nProvider i18n={i18n}>
                {children}
            </I18nProvider>
        </ApolloProvider>
    </PagePropsProvider>
)

export default AppProviders
