import { useEffect } from 'react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'

const usePageLoader = () => {
    const { events } = useRouter()
    useEffect(() => {
        NProgress.configure({ showSpinner: false })
        const handleRouteChange = () => NProgress.start()
        const handleRouteDone = () => NProgress.done()
        events.on('routeChangeStart', handleRouteChange)
        events.on('routeChangeComplete', handleRouteDone)
        events.on('routeChangeError', handleRouteDone)
        return () => {
            events.off('routeChangeStart', handleRouteChange)
            events.off('routeChangeComplete', handleRouteDone)
            events.off('routeChangeError', handleRouteDone)
        }
    }, [])
}

export default usePageLoader
