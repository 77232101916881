import React from 'react'
import { i18n } from '@lingui/core'
import createLocales from '../locales/index'
import AppStateProvider from './providers/AppStateProvider'
import AppProviders from './AppProviders'

class AppWithState extends React.Component {
    constructor(props) {
        super(props)
        const { router } = props
        this.i18n = createLocales(router.locale, router.locales)
        this.changeLanguage = this.changeLanguage.bind(this)
        this.state = {
            changeLanguage: this.changeLanguage,
        }
    }

    changeLanguage(locale) {
        i18n.activate(locale)
    }

    render() {
        const { apolloClient, children, pageProps } = this.props
        return (
            <AppStateProvider value={this.state}>
                <AppProviders
                    i18n={this.i18n}
                    apolloClient={apolloClient}
                    pageProps={pageProps}
                >
                    {children}
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
